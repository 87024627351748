import PropTypes from 'prop-types';
import {
  nameType, moneyType, emailsType, addressType, phonesType, telephoneContactType, notesType,
} from 'types/common';
import { mediaType, variantsType } from 'types/media';
import { directoryListingType, directoryListingStatusType, directoryListingContactType } from 'types/directoryListing';
import { staffMemberType } from 'types/staffMember';
import { organisationalUnitType } from 'types/organisationalUnit';

export const bereavementNoteType = PropTypes.shape({
  id: PropTypes.string,
  content: PropTypes.string,
  category: PropTypes.string,
  createdDateTime: PropTypes.string,
  createdById: PropTypes.string,
  updatedDateTime: PropTypes.string,
  updateById: PropTypes.string,
});

export const bodySizeType = PropTypes.shape({
  length: PropTypes.number,
  width: PropTypes.number,
  depth: PropTypes.number,
  units: PropTypes.string,
});

export const deceasedPersonType = PropTypes.shape({
  id: PropTypes.string,
  name: nameType,
  deathDateTime: PropTypes.string,
  dateOfBirth: PropTypes.string,
  ageOfDeath: PropTypes.number,
  collectionLocation: addressType,
  alsoKnownAs: PropTypes.string,
  maidenName: PropTypes.string,
  faith: PropTypes.string,
  address: addressType,
  bodySize: bodySizeType,
});

export const clinicianType = PropTypes.shape({
  name: PropTypes.string,
  directoryListingId: PropTypes.string,
  directoryListing: directoryListingType,
});

export const obituaryType = PropTypes.shape({
  displayName: PropTypes.string,
  biography: PropTypes.string,
  status: PropTypes.string,
  preferences: PropTypes.shape({
    allowCharities: PropTypes.bool,
  }),
  ownerContactDetails: PropTypes.shape({
    obituaryOwnerEmail: PropTypes.string,
    obituaryOwnerPhoneNumber: telephoneContactType,
  }),
  ownershipAccepted: PropTypes.bool,
});

export const pdfType = PropTypes.shape({
  id: PropTypes.string,
  locationId: PropTypes.string,
  tenantId: PropTypes.string,
  uri: PropTypes.string,
  privacy: PropTypes.string,
  variants: PropTypes.arrayOf(variantsType),
});

export const obituaryCategoryInformationType = PropTypes.shape({
  onlineObituaryMessate: PropTypes.string,
  isOnlineObituaryServiceInfo: PropTypes.bool,
  isOnlineObituaryCommittal: PropTypes.bool,
  onlineReception: PropTypes.shape({
    address: PropTypes.addressType,
    dateTime: PropTypes.string,
  }),
  onlineAdministratorName: PropTypes.shape({
    title: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }),
  onlineAdministratorEmail: PropTypes.string,
  onlineAdministratorPhone: phonesType,
  isOfflineServiceInfo: PropTypes.bool,
  isOfflineCommittal: PropTypes.bool,
  isOfflineReception: PropTypes.bool,
  offlinePublicationNames: PropTypes.string,
});


export const musicSelectionType = PropTypes.shape({
  id: PropTypes.string,
  moment: PropTypes.string,
  time: PropTypes.string,
  title: PropTypes.string,
});

export const serviceMusicType = PropTypes.shape({
  id: PropTypes.string,
  serviceType: PropTypes.string,
  selections: PropTypes.arrayOf(musicSelectionType),
});

export const journeyType = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  dateTime: PropTypes.string,
  location: addressType,
});

export const vehiclesInfoType = PropTypes.shape({
  id: PropTypes.string,
  isHearse: PropTypes.bool,
  noOfPassengers: PropTypes.number,
  journeys: PropTypes.arrayOf(journeyType),
});

export const vehiclesType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
  vehicles: PropTypes.arrayOf(vehiclesInfoType),
});

export const viewingsType = PropTypes.shape({
  location: addressType,
  viewingDateTime: PropTypes.string,
});

export const hazardousImplantsType = PropTypes.shape({
  implant: PropTypes.string,
  dateRemoved: PropTypes.string,
});

export const careType = PropTypes.shape({
  isEmbalmingRequired: PropTypes.bool,
  hazardousImplants: PropTypes.arrayOf(hazardousImplantsType),
  clothes: PropTypes.string,
  clothesDisposal: PropTypes.string,
  isViewingRequired: PropTypes.bool,
  viewings: PropTypes.arrayOf(viewingsType),
  notes: PropTypes.string,
});

export const crematoriumType = PropTypes.shape({
  eventDateTime: PropTypes.string,
  bearerType: PropTypes.string,
  familyBearerQuantity: PropTypes.number,
  isConfirmed: PropTypes.bool,
  isCurtainsOpen: PropTypes.bool,
  isFamilyWalking: PropTypes.bool,
  isTrolley: PropTypes.bool,
  handlingOfAshesType: PropTypes.string,
  alternativeLocation: addressType,
  reservedSeatingQuantity: PropTypes.number,
  isWitnessCommittal: PropTypes.bool,
  cremationReference: PropTypes.string,
  isUnattendedCremation: PropTypes.bool,
});

export const serviceVenueType = PropTypes.shape({
  eventDateTime: PropTypes.string,
  durationHours: PropTypes.number,
  isConfirmed: PropTypes.bool,
  alternativeLocation: addressType,
  reservedSeatingQuantity: PropTypes.number,
});

export const cemeteryType = PropTypes.shape({
  eventDateTime: PropTypes.string,
  isExistingGrave: PropTypes.bool,
  existingGraveNumber: PropTypes.string,
  isExistingDeedsPresent: PropTypes.bool,
  graveSize: PropTypes.string,
  currentOccupancy: PropTypes.number,
  isConfirmed: PropTypes.bool,
  alternativeLocation: addressType,
  graveType: PropTypes.string,
});

export const productCategoryMaxQuantitiesType = PropTypes.shape({
  category: PropTypes.string,
  value: PropTypes.number,
});

export const serviceCategoryMaxQuantitiesType = PropTypes.shape({
  category: PropTypes.string,
  value: PropTypes.number,
});

export const calculationType = PropTypes.shape({
  finalTotal: moneyType,
  discounts: moneyType,
  totalWithoutDiscounts: moneyType,
  totalDisbursements: moneyType,
});

export const coffinSizeType = PropTypes.shape({
  feetOfLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  inchesOfLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  depth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
});


export const coffinType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
  engraving: PropTypes.string,
  size: coffinSizeType,
  shape: PropTypes.string,
  handleType: PropTypes.string,
});

export const urnType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
  engraving: PropTypes.string,
});

export const memorialisationType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
  note: PropTypes.string,
});

export const officiantType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
  serviceType: PropTypes.string,
  dateTime: PropTypes.string,
  name: nameType,
  officiantPhone: PropTypes.string,
});

export const musicType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
  serviceMusic: PropTypes.arrayOf(serviceMusicType),
  serviceOrganists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      serviceType: PropTypes.string,
      name: PropTypes.string,
      dateTime: PropTypes.string,
    }),
  ),
});

export const flowersType = PropTypes.shape({
  isConfirmed: PropTypes.bool,
});

export const charitiesType = PropTypes.shape({
  onlineCharityNames: PropTypes.arrayOf(PropTypes.string),
  offlineCharityNames: PropTypes.arrayOf(PropTypes.string),
});

export const categoryInformationType = PropTypes.shape({
  crematorium: crematoriumType,
  serviceVenue: serviceVenueType,
  cemetery: cemeteryType,
  care: careType,
  vehicles: vehiclesType,
  coffin: coffinType,
  urn: urnType,
  officiant: officiantType,
  music: musicType,
  flowers: flowersType,
  charities: charitiesType,
  other: PropTypes.shape({
    note: PropTypes.string,
  }),
  obituary: obituaryCategoryInformationType,
});

export const pricesType = PropTypes.shape({
  sale: moneyType,
  retail: moneyType,
  cost: moneyType,
});

export const supplierType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: addressType,
  emails: emailsType,
  phones: phonesType,
  website: PropTypes.arrayOf(PropTypes.string),
  imageId: PropTypes.string,
  isSupplier: PropTypes.bool,
  note: PropTypes.string,
  status: directoryListingStatusType,
  contacts: PropTypes.arrayOf(directoryListingContactType),
  image: PropTypes.string,
});

export const serviceType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  timeUpdated: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  prices: pricesType,
  salesTaxBand: PropTypes.string,
  category: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  imageIds: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  reference: PropTypes.string,
  isGuideService: PropTypes.bool,
  note: PropTypes.string,
  supplierId: PropTypes.string,
  hasQuantity: PropTypes.bool,
  organisationalUnitIds: PropTypes.arrayOf(PropTypes.string),
  displayAsDisbursementToBereaved: PropTypes.bool,
  images: PropTypes.arrayOf(mediaType),
  supplier: supplierType,
  organisationalUnit: PropTypes.arrayOf(organisationalUnitType),
  isNewItem: PropTypes.bool,
  vehicleType: PropTypes.string,
});

export const serviceSelectionType = PropTypes.shape({
  serviceId: PropTypes.string,
  quantity: PropTypes.number,
  overridePrice: moneyType,
  isPackageSelection: PropTypes.bool,
  service: serviceType,
});

export const serviceVariantType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  prices: pricesType.isRequired,
  isGuidePrice: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(['ACTIVE', 'DELETED']),
  isDeleted: PropTypes.bool,
  imageId: PropTypes.string,
  image: mediaType,
});

export const productType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  timeUpdated: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  prices: pricesType,
  salesTaxBand: PropTypes.string,
  category: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  imageIds: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  reference: PropTypes.string,
  isGuideProduct: PropTypes.bool,
  note: PropTypes.string,
  supplierId: PropTypes.string,
  hasQuantity: PropTypes.bool,
  organisationalUnitIds: PropTypes.arrayOf(PropTypes.string),
  displayAsDisbursementToBereaved: PropTypes.bool,
  images: PropTypes.arrayOf(mediaType),
  supplier: supplierType,
  organisationalUnit: PropTypes.arrayOf(organisationalUnitType),
  isNewItem: PropTypes.bool,
});

export const packageType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  name: PropTypes.string,
  price: moneyType,
  nominalCode: PropTypes.string,
  salesTaxBand: PropTypes.string,
  isFixed: PropTypes.bool,
  description: PropTypes.string,
  productIds: PropTypes.arrayOf(PropTypes.string),
  serviceIds: PropTypes.arrayOf(PropTypes.string),
  productCategoryMaxQuantities: PropTypes.arrayOf(productCategoryMaxQuantitiesType),
  serviceCategoryMaxQuantities: PropTypes.arrayOf(serviceCategoryMaxQuantitiesType),
  status: PropTypes.string,
  products: PropTypes.arrayOf(productType),
  services: PropTypes.arrayOf(serviceType),
});

export const packageSelectionType = PropTypes.shape({
  packageId: PropTypes.string,
  overridePrice: moneyType,
  package: packageType,
});

export const productSelectionType = PropTypes.shape({
  productId: PropTypes.string,
  quantity: PropTypes.number,
  overridePrice: moneyType,
  isPackageSelection: PropTypes.bool,
  product: productType,
});

export const createdByType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  name: nameType,
  image: pdfType,
  email: PropTypes.string,
  policy: PropTypes.shape({
    memberships: PropTypes.arrayOf(PropTypes.string),
    scopes: PropTypes.arrayOf(PropTypes.string),
  }),
  isActive: PropTypes.bool,
});

export const arrangementType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  timeUpdated: PropTypes.string,
  committalType: PropTypes.string,
  reference: PropTypes.string,
  productSelections: PropTypes.arrayOf(productSelectionType),
  serviceSelections: PropTypes.arrayOf(serviceSelectionType),
  packageSelection: packageSelectionType,
  familyArrangedCategories: PropTypes.shape({
    productCategories: PropTypes.arrayOf(PropTypes.string),
    serviceCategories: PropTypes.arrayOf(PropTypes.string),
  }),
  duplicatedFromId: PropTypes.string,
  discountType: PropTypes.string,
  globalDiscount: moneyType,
  pdfId: PropTypes.string,
  createById: PropTypes.string,
  categoryInformation: categoryInformationType,
  calculation: calculationType,
  pdf: pdfType,
  createdBy: createdByType,
  hideDiscount: PropTypes.bool,
});

export const ownerType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  name: nameType,
  image: PropTypes.string,
  email: PropTypes.string,
  policy: PropTypes.shape({
    memberships: PropTypes.arrayOf(PropTypes.string),
    scopes: PropTypes.arrayOf(PropTypes.string),
  }),
  isActive: PropTypes.bool,
});

export const homeType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  address: addressType,
  phones: phonesType,
  emails: emailsType,
  websites: PropTypes.arrayOf(PropTypes.string),
  note: PropTypes.string,
  parentId: PropTypes.string,
  imageId: PropTypes.string,
});

export const contactsType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  emails: emailsType,
  phones: phonesType,
});

export const appointmentType = PropTypes.shape({
  dateTime: PropTypes.string,
  address: addressType,
  staffMemberId: PropTypes.string,
  staffMember: staffMemberType,
});

export const bereavedPersonType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  name: nameType,
  address: addressType,
  billingAddress: addressType,
  phones: phonesType,
  emails: emailsType,
  notes: notesType,
});

export const bereavedPersonConnectionType = PropTypes.shape({
  id: PropTypes.string,
  bereavedPersonId: PropTypes.string,
  relationshipToDeceased: PropTypes.string,
  isPrimaryContact: PropTypes.bool,
  isBillPayer: PropTypes.bool,
  isNextOfKin: PropTypes.bool,
  contactPreferences: PropTypes.arrayOf(PropTypes.string),
  bereavedPerson: bereavedPersonType,
});

export const bereavementType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType),
  deceasedPeople: PropTypes.arrayOf(deceasedPersonType).isRequired,
  clinicians: PropTypes.arrayOf(clinicianType),
  coronerDirectoryId: PropTypes.string,
  coronerName: PropTypes.string,
  firstCallType: PropTypes.string,
  advisoryPossessions: PropTypes.string,
  ownerId: PropTypes.string,
  homeId: PropTypes.string,
  obituaryConsentGivenTime: PropTypes.string,
  obituary: obituaryType,
  arrangements: PropTypes.arrayOf(arrangementType),
  confirmedArrangementId: PropTypes.string,
  appointment: appointmentType,
  notes: PropTypes.arrayOf(bereavementNoteType),
  coronerDirectoryListing: directoryListingType,
  home: homeType,
  owner: ownerType,
});

export const eyeCareType = PropTypes.shape({
  hasEyeCareBeenCompleted: PropTypes.bool,
  other: PropTypes.string,
});

export const mouthCareType = PropTypes.shape({
  haveDenturesBeenInserted: PropTypes.bool,
  haveSuturesBeenClosed: PropTypes.bool,
  other: PropTypes.string,
});

export const disinfectionType = PropTypes.shape({
  eyesHaveBeenDisinfected: PropTypes.bool,
  noseHasBeenDisinfected: PropTypes.bool,
  mouthHasBeenDisinfected: PropTypes.bool,
  remainsHaveBeenDisinfected: PropTypes.bool,
  orificesHaveBeenPacked: PropTypes.bool,
});

export const firstOfficesType = PropTypes.shape({
  eyeCare: eyeCareType,
  mouthCare: mouthCareType,
  disinfection: disinfectionType,
  notes: PropTypes.string,
});

export const mortuaryType = PropTypes.shape({
  bodyHasBeenEmbalmed: PropTypes.bool,
  bodyHasBeenDressed: PropTypes.bool,
  bodyHasBeenEncoffined: PropTypes.bool,
  notes: PropTypes.string,
});

export const transferType = PropTypes.shape({
  id: PropTypes.string,
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  fromLocation: PropTypes.oneOfType([PropTypes.string, addressType]),
  toLocation: PropTypes.oneOfType([PropTypes.string, addressType]),
  isComplete: PropTypes.bool,
  instructions: PropTypes.string,
  isPossessionsChecked: PropTypes.bool,
  isIdentificationChecked: PropTypes.bool,
  completionNotes: PropTypes.string,
});

export const totalsType = PropTypes.shape({
  uninvoiced: PropTypes.number,
  withOutstandingBalance: PropTypes.number,
  totalInvoiceTemplatesUninvoiced: PropTypes.number,
});

export const deedActionType = PropTypes.shape({
  action: PropTypes.string,
  timeCreated: PropTypes.string,
  createdById: PropTypes.string,
});

export const deedType = PropTypes.shape({
  id: PropTypes.string,
  rightOfBurialNumber: PropTypes.string,
  graveNumber: PropTypes.string,
  section: PropTypes.string,
  dateReceived: PropTypes.string,
  cemeteryName: PropTypes.string,
  notes: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.any),
  actions: PropTypes.arrayOf(deedActionType),
});

export const ashesRecordActionType = PropTypes.shape({
  createdById: PropTypes.string,
  createdDateTime: PropTypes.string,
  actionTaken: PropTypes.string,
  locationScattered: PropTypes.string,
  returnToClientMethod: PropTypes.string,
});

export const ashesRecordType = PropTypes.shape({
  id: PropTypes.string,
  cremationCertificateNumber: PropTypes.string,
  storageLocation: PropTypes.string,
  personToReceiveAshes: PropTypes.string,
  actions: PropTypes.arrayOf(ashesRecordActionType),
});

export const possessionActionType = PropTypes.shape({
  action: PropTypes.string.isRequired,
  timeCreated: PropTypes.string.isRequired,
  createdById: PropTypes.string,
});

export const possessionType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionHistory: PropTypes.arrayOf(possessionActionType),
  imageId: PropTypes.string,
  familyWishes: PropTypes.string,
  note: PropTypes.string,
  requiredForViewing: PropTypes.bool.isRequired,
});
