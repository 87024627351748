import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Amount from 'components/common/Amount';
import { totalsType } from 'types/bereavement';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AccountsListSummary = ({ totals, hasXeroIntegration }) => (
  <div className={rcStyles.balances}>
    <div className={styles.u_soft__right_double}>
      <Amount
        label={t('Uninvoiced')}
        labelClasses={[
          rcStyles.is_uninvoiced,
          rcStyles.amount_label,
        ]}
        amount={totals && (!hasXeroIntegration ? totals.uninvoiced : totals.totalInvoiceTemplatesUninvoiced)}
        amountProps={{ 'data-test-id': 'accountsUninvoiced' }}
      />
    </div>

    {!hasXeroIntegration && (
      <Amount
        label={t('Outstanding balance')}
        labelClasses={[
          rcStyles.is_outstanding,
          rcStyles.amount_label,
        ]}
        amount={totals && totals.withOutstandingBalance}
        amountProps={{ 'data-test-id': 'accountsWithOutstandingBalance' }}
      />
    )}
  </div>
);

AccountsListSummary.propTypes = {
  totals: totalsType.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
};

export default AccountsListSummary;
